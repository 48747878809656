:root {
    --question-background-color: rgba(0, 0, 0, 0.7);
    --question-text-color: #fff;
    --option-background-color: rgba(0, 0, 0, 0.9);
    --option-hover-background-color: rgba(0, 0, 0, 0.7);
    --option-text-color: #fff;
    --primary-font-size: 3vw;
    --username-font-size: 2vw;
    --rank-font-size: 1vw;
    --max-content-width: 90vw;
    --tag-background-color: rgba(0, 0, 0, 0.1);
  }
  .leaderboard-container {
    display: grid; 
    grid-template-columns: repeat(2, 1fr); 
    gap: 4px; 
    background-color: transparent;
    border-radius: 12px;
    width: auto; 
    height: auto; 
    overflow-y: auto; 
    box-sizing: border-box; 
    border: 0
  }

  .leaderboard-item {
    display: flex;
    align-items: center;
    padding: 4px;
    margin: 6px auto;
/*     border: 5px solid rgba(0,0,0,1); */
    border-radius: 16px;
    background-color: rgba(0,0,0,0.8);
  }

  .leaderboard-item[data-current-user="true"] {
    background-color: rgba(255, 203, 71, 0.1);
    border: 2px solid rgba(255, 203, 71, 0.5);
  }

  .rank-container {
    width: 20px;
    text-align: center;
  }

  .rank-text {
    color: white;
    font-size: var(--rank-font-size);
    font-weight: bold;
  }

  .user-info {
    flex: 1;
    display: flex;
    align-items: center;
    margin-left: 12px;
  }

  .avatar-container {
    margin-right: 0px;
  }

  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(255,255,255,0.8);
    overflow: hidden;
  }

  .avatar-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .avatar span {
    color: rgba(255,255,255,0.8);
    font-size: 12px;
  }

  .username {
    color: white;
    font-size: var(--username-font-size);
    font-weight: 500;
    margin-right: 20px
  }

  .score {
    background-color: 'transparent';
    padding: 4px 8px;
    border-radius: 4px;
    color: white;
    font-size: 32px;
    margin-left: auto;
  }