

.topbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  align-items: top;
  height: 60px;
  width: 100%;    
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0,1),
    rgba(0, 0, 0, 0)
  );
  padding:0;
  box-sizing: border-box;
  padding: 16px 16px;
  padding-left: calc(4vw);
  padding-right: calc(4vw);
  animation: slideDown 0.5s ease-in-out forwards;

}

.topbar-section {
  display: flex;
  align-items: center;
  width:50vw;
  justify-content: center;
}
.logo{
  width:50px;
}
.left {
  justify-content: flex-start;
  align-items: center;
  flex-grow: 0;
}
 h2 {
  color: "#ffffff";
  margin: 0;
  text-shadow: 0.1em 0.1em #000;
  font-weight: 300;
  font-size: 0.5em;
  text-align: center;
  font-family: 'Inter';
  font-weight: 700;
}

.center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  color: white;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.center h1 {
  font-size: 1.6em;
  text-shadow: .06em .06em black;
  font-weight: 100;
  margin-bottom: 2px;
}

.center h2 {
  line-height: 1;
  margin: 0;
  font-family: sans-serif;
  text-shadow: .02em .02em black;
  font-size: 01em;
}

.right {
  justify-content: flex-end;
  flex-grow: 0;
  padding-right: calc(1vw);
}

.center-logo {
  width: 240px;
}

.icon {
  color: #000;
  font-size: 2em;
  transition: color 1s;
  margin: 10px;
}

.icon.active {
  color: #fff;
}

@media (max-width: 768px) {
  .topbar {
    height: 60px;
    padding: 5px 10px;
  }

  .center-logo {
    width: 80px;
  }

  .center h1 {
    font-size: 1.2em;
  }

  .center h2 {
    font-size: 0.7em;
  }

  .icon {
    font-size: 1.2em;
    margin: 5px;
  }
}

@media (max-width: 480px) {
  .center h1 {
    font-size: 1em;
  }

  .center h2 {
    font-size: 0.6em;
  }

  .icon {
    font-size: 1em;
    margin: 3px;
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}
