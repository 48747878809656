@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dongle:wght@700&display=swap');

.App {
  height: 100%;
  display:flex;
  flex:1;

}
#root {
  height:100%;
  display: flex;
  flex:1
}
html,body{
  background-color: black;
/* Reference from public directory */
height:100%;
display: flex;
flex:1;
  /*font-family: "Varela Round", sans-serif;*/
  font-style: normal;
  overflow: hidden;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.main-content {
  width: 100%;
  height: calc(100vh - 60px); /* Viewport height minus 160px */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top:60px;
  overflow:hidden;
  /* Optional: Add padding or margin if needed */
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@property --a {
  /* must register --a to animate it */
  syntax: "<angle>";
  initial-value: 0deg;
  /* used only on pseudo, nowhere to be inherited, 
   * better perf if set false, see 
   * https://www.bram.us/2024/10/03/benchmarking-the-performance-of-css-property/ */
  inherits: false;
}

.bling {
  /* hide outer part of glow */
  overflow: hidden;
  /* needed for absolutely positioned pseudo */
  position: relative;

  /* adjust aspect-ratio OR height IF height not given by content */
  aspect-ratio: 1;
  /* round outer card corners */
  border-radius: 0.5em;
  /* text & layout styles below just for prettifying */
  place-self: center;
  place-content: center;
  padding: 0.5em;
  color: #ededed;
  font: clamp(1em, 2vw + 2vh, 2em) sans-serif;
  text-align: center;

  text-wrap: balance;
}

.bling::before {
  /* grid doesn't work for stacking when a stacked item is text node */
  position: absolute;
  /* place behind card content, so card text is selectable, etc */
  z-index: -1;
  /* best if inset is at least half the border-width with minus */
  inset: -1em;
  /* reserve space for border */
  border: solid 1.35em;
  border-image: conic-gradient(from var(--a),  #FFCB47, #00E096,#22bcef,#ffffff) 1;
  /* blur this pseudo */
  filter: blur(1.75em);
  /* tweak animation duration as necessary */
  animation: a 5s linear infinite;
  /* needed so pseudo is displayed */
  content: "";
}

/* animate --a from its initial-value 0deg to 1turn */
@keyframes a {
  to {
    --a: 1turn ;
  }
}
