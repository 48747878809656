.bottom-section {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    animation: slideUp 0.5s ease-in-out forwards;
    z-index: 10;
    flex:auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.0),
      rgba(0, 0, 0, 1)
    );
  }
  .bottom-section .option-letter {
    width: 5vmin;
    height: 5vmin;
    background-color: #000;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2vmin;
    flex-shrink: 0;
  }
  
  .bottom-section .option-letter span {
    font-size: 2.5vmin;
    color:white;
  }
  @keyframes slideUp {
    from { bottom: -120px; }
    to { bottom: 0; }
  }
  /* BottomSection.css */

.bottom-section {
  width: 100%;
  padding: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 30px;

}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-container {
  display: flex;
  align-items: center;
  overflow-x: auto;
  
  padding:10px;
}

.avatar-wrapper {
  margin: 0 8px;
  position: relative;
  
}

.unanswered-avatar,
.answered-avatar,
.correct-avatar,.incorrect-avatar,.inactive-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 5px solid white;
}

.inactive-avatar {
  border: 5px solid rgba(255,255,255,0.3);
  background-color: rgba(0, 0, 0, 0.1);
}
.unanswered-avatar {
  border: 5px solid white;
  border-color: white;
  opacity:0.7;
}
.answered-avatar {
  border: 5px solid white;
  box-shadow: 0 0 10px (255,255,255,0.3);
}

.correct-avatar {
  background: #00E096;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.correct-avatar .avatar-initials {
  color: #000000;
}
.incorrect-avatar {
  background: #EB5E55;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.incorrect-avatar .avatar-initials {
  color: #000000;
}
.inactive-avatar .avatar-initials {
  color: rgba(255,255,255,0.3);
}
.avatar-initials {
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
}


.answer-badge {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 64px;
  height: 64px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-weight: bold;
  font-size: 14px;
  animation: popIn 0.3s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.streak-badge {
  position: absolute;
  top: -16px;
  right: -16px;
  width: 48px;
  height: 48px;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-weight: bold;
  font-size: 20px;
/*   animation: pulse 0.3s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
  font-size: 3em;
}
.a-badge {
  position: absolute;
  top: -16px;
  right: -16px;
  width: 48px;
  height: 48px;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-weight: bold;
  font-size: 1.6em;
}
.checkmark-icon {
  position: absolute;
  bottom: -4px;
  right: -4px;
  color: #ffffff;
  background-color: #00E096;
  border-radius: 50%;
  padding: 2px;
  font-size: 12px;
}
.avatar-name{
font-size: 0.6em;
}
/* Add these new animations to your existing CSS */

@keyframes slideIn {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 0;
  }
}

@keyframes rotateColors {
  0% {
    border-color: #FFCB47;
  }
  25% {
    border-color: #00E096;
  }
  50% {
    border-color: #22bcef;
  }
  75% {
    border-color: #FFFFFF;
  }
  100% {
    border-color: #FFCB47;
  }
}

.new-player {
  animation: slideIn 0.5s ease-in forwards;
}

.leaving-player {
  animation: slideOut 0.5s ease-out forwards;
}

.rotating-border {
  animation: rotateColors 2s linear infinite;
  overflow: hidden;
  /* needed for absolutely positioned pseudo */
  position: relative;

  /* adjust aspect-ratio OR height IF height not given by content */
  aspect-ratio: 1;
}
.rotating-border::before {
  /* grid doesn't work for stacking when a stacked item is text node */
  position: absolute;
  /* place behind card content, so card text is selectable, etc */
  z-index: -1;
  /* best if inset is at least half the border-width with minus */
  inset: -0.5em;
  /* reserve space for border */
  border: solid 1em;
  border-image: conic-gradient(from var(--a),  #FFCB47, #00E096,#22bcef,#ffffff) 1;
  /* blur this pseudo */
  filter: blur(.5em);
  /* tweak animation duration as necessary */
  animation: a 5s linear infinite;
  /* needed so pseudo is displayed */
  content: "";
}
