:root {
  --question-background-color: rgba(0, 0, 0, 0.7);
  --question-text-color: #fff;
  --option-background-color: rgba(0, 0, 0, 0.9);
  --option-hover-background-color: rgba(0, 0, 0, 0.7);
  --option-text-color: #fff;
  --primary-font-size: 3vw;
  --option-font-size: 2vw;
  --max-content-width: 100vw;
  --tag-background-color: rgba(0, 0, 0, 0.1);
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.content-container {
  display: flex;
  flex-direction: row;
  width: var(--max-content-width);
  max-width: var(--max-content-width);
  background-color: transparent;
  height: calc(100vh - 260px);
}

.tag-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: var(--max-content-width);
  margin: 0 auto;
}

.tag {
  align-items: center;
  background-color: var(--tag-background-color);
  padding: 2vmin;
  border-radius: 10px;
  cursor: pointer;
  width: auto;
  margin: 2vmin;
  font-size: 2vmin;
}

.question-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 0vh;
  box-sizing: border-box;

}

.question-text {
  position: relative;
  background-color: var(--question-background-color);
  border-radius: 10px;
  padding: 3vmin;
  width: 100%;
  display: flex;
  opacity: 0;
  will-change: opacity;

}

.question-text h2 {
  color: var(--question-text-color);
  margin: 0;
  text-shadow: 0.1em 0.1em #000;
  font-weight: 300;
  font-size: var(--primary-font-size);
  text-align: center;
  font-family: 'Inter';
  font-weight: 700;
  align-items: center;
  place-items: 'center';
}

.options {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  gap: 8px;
  height: calc(100vh - 200px);
  margin:16px;
  margin-top:0;
}

.option {
  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--option-background-color);
  padding: 2vmin;
  border-radius: 16px;
  transition: background-color 0.5s;
  border: 5px solid rgba(0, 0, 0, 0.8);
  font-family: 'Inter';
  font-weight: bold;
  opacity: 0;
  will-change: opacity;
}

.option-letter {
  width: 5vmin;
  height: 5vmin;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2vmin;
  flex-shrink: 0;
}

.option-letter span {
  font-size: 2.0vmin;
}

.option.correct .option-letter {
  background-color: black;
}

.option.correct .option-letter span {
  color: white;
}

.option-text {
  flex-grow: 1;
}

.option-text span {
  font-size: var(--option-font-size);
  color: var(--option-text-color);
  text-shadow: 0.03em 0.03em black;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.option.fade-in {
  animation: fadeIn 0.3s ease forwards;
}

.option.fade-out {
  animation: fadeOut 0.3s ease forwards;
}

.option.correct {
  opacity: 1;
  background: #00e096;
  text-shadow: none;
  color: black;
}

.option.correct span {
  color: black;
  text-shadow: 0.03em 0.03em rgba(0, 0, 0, 0.2);
}

.option.wrong {
  opacity: 0.1;
  animation: none;
}

.question-text.fade-in {
  animation: fadeIn 0.5s ease forwards;
}

.question-text.fade-out {
  animation: fadeOut 0.5s ease forwards;
}

progress {
  width: 50px;
  border-radius: 0;
  margin: 2vmin;
}