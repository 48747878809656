/* ProgressBar.css */

/* General Styles for <progress> */
.progress-container {
    width: 30vw;
    text-align: center;
  }
  
  .progress-container progress {
    width: 100%;
    height: 20px;
    /* Remove default appearance */
    -webkit-appearance: none;
    appearance: none;
  }
  
  /* Chrome, Safari, Edge, and Opera */
  .progress-container progress::-webkit-progress-bar {
    background-color: rgba(0,0,0,0.9); /* Background color */
    border-radius: 10px;
  }
  
  .progress-container progress::-webkit-progress-value {
    background: linear-gradient(120deg, #FFCB47, #00E096,#22bcef);
    border-radius: 10px;
  }
  
  /* Firefox */
  .progress-container progress::-moz-progress-bar {
    background: linear-gradient(120deg, #FFCB47, #00E096,#22bcef);
    border-radius: 10px;
  }
  
  /* IE and Edge Legacy */
  .progress-container progress::-ms-fill {
    background: linear-gradient(120deg, #FFCB47, #00E096,#22bcef);
    border-radius: 10px;
  }